import Gallery1 from '@assets/images/gallery-1.jpg';
import Gallery2 from '@assets/images/gallery-2.jpg';
import Gallery3 from '@assets/images/gallery-3.jpg';
import Gallery4 from '@assets/images/gallery-4.jpg';
import Gallery5 from '@assets/images/gallery-5.jpg';
import Gallery6 from '@assets/images/gallery-6.jpg';
import Gallery7 from '@assets/images/gallery-7.jpg';
import Gallery8 from '@assets/images/gallery-8.jpg';

// import GalleryT1 from '@assets/images/gallery-t-1.jpg';
// import GalleryT4 from '@assets/images/gallery-t-4.jpg';
// import Gallery5 from '@assets/images/gallery-5.jpg';
// import GalleryT5 from '@assets/images/gallery-t-5.jpg';
// import Image5 from '@assets/images/slide-5.jpg';
// import Image5thumb from '@assets/images/slide-5-thumb.jpg';
// import Image6 from '@assets/images/slide-6.jpg';
// import Image6thumb from '@assets/images/slide-6-thumb.jpg';
// import Gallery6 from '@assets/images/gallery-6.jpg';
// import GalleryT6 from '@assets/images/gallery-t-6.jpg';
// import Gallery7 from '@assets/images/gallery-7.jpg';
// import GalleryT7 from '@assets/images/gallery-t-7.jpg';
// import Gallery8 from '@assets/images/gallery-8.jpg';
// import GalleryT8 from '@assets/images/gallery-t-8.jpg';
// import Gallery9 from '@assets/images/gallery-9.jpg';
// import GalleryT9 from '@assets/images/gallery-t-9.jpg';
// import Gallery10 from '@assets/images/gallery-10.jpg';
// import GalleryT10 from '@assets/images/gallery-t-10.jpg';
// import Gallery11 from '@assets/images/gallery-11.jpg';
// import GalleryT11 from '@assets/images/gallery-t-11.jpg';
// import Gallery12 from '@assets/images/gallery-12.jpg';
// import GalleryT12 from '@assets/images/gallery-t-12.jpg';
// import Gallery13 from '@assets/images/gallery-13.jpg';
// import GalleryT13 from '@assets/images/gallery-t-13.jpg';
// import Gallery14 from '@assets/images/gallery-14.jpg';
// import GalleryT14 from '@assets/images/gallery-t-14.jpg';
// import Gallery15 from '@assets/images/gallery-15.jpg';
// import GalleryT15 from '@assets/images/gallery-t-15.jpg';

export const photos = [
  {
    original: Gallery1,
    thumbnail: Gallery1,
  },
  {
    original: Gallery2,
    thumbnail: Gallery2,
  },
  {
    original: Gallery3,
    thumbnail: Gallery3,
  },
  {
    original: Gallery4,
    thumbnail: Gallery4,
  },
  {
    original: Gallery5,
    thumbnail: Gallery5,
  },
  {
    original: Gallery6,
    thumbnail: Gallery6,
  },
  {
    original: Gallery7,
    thumbnail: Gallery7,
  },
  {
    original: Gallery8,
    thumbnail: Gallery8,
  },
  // {
  //   original: Image6,
  //   thumbnail: Image6thumb,
  // },
  // {
  //   original: Image5,
  //   thumbnail: Image5thumb,
  // },
  // {
  //   original: Gallery5,
  //   thumbnail: GalleryT5,
  // },
  // {
  //   original: Gallery6,
  //   thumbnail: GalleryT6,
  // },
  // {
  //   original: Gallery7,
  //   thumbnail: GalleryT7,
  // },
  // {
  //   original: Gallery8,
  //   thumbnail: GalleryT8,
  // },
  // {
  //   original: Gallery9,
  //   thumbnail: GalleryT9,
  // },
  // {
  //   original: Gallery10,
  //   thumbnail: GalleryT10,
  // },
  // {
  //   original: Gallery11,
  //   thumbnail: GalleryT11,
  // },
  // {
  //   original: Gallery12,
  //   thumbnail: GalleryT12,
  // },
  // {
  //   original: Gallery13,
  //   thumbnail: GalleryT13,
  // },
  // {
  //   original: Gallery14,
  //   thumbnail: GalleryT14,
  // },
  // {
  //   original: Gallery15,
  //   thumbnail: GalleryT15,
  // },
];
